import React from "react";
import { FaLinkedin, FaInstagram, FaTwitter } from 'react-icons/fa';
import { useMediaQuery } from 'react-responsive'

const Slider = () => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 765px)' })

  return (
    <>
      <div className="shane_tm_hero" id="home" data-style="one">
        <div className="background">
          <div
            className="image"
            style={{ backgroundImage: "url(img/header/rachel-golden-headshot.jpg)" }}
          ></div>
        </div>

        <div className="container">
          <div className="content">
            <div className="name_wrap">
              <h3>
                <span>Rachel</span> Golden<span className="overlay_effect"></span>
              </h3>
            </div>

            <div className="job_wrap">
              <span className="job" style={{ fontSize: '18px', fontFamily: "IBM Plex Sans", fontWeight: 400 }}>
                Experienced storyteller specializing in brand & digital marketing
                <span className="overlay_effect"></span>
              </span>
            </div>

            <div>
              <a href="https://www.linkedin.com/in/rachel-golden-10b56410b/" target="_blank" rel="noreferrer">
                <FaLinkedin color={isTabletOrMobile ? "#C0C0C0" : "#28282B"} style={{ minHeight: "24px", minWidth: "24px"}} />
              </a>
              <a href="https://twitter.com/rachellllynn5" target="_blank" rel="noreferrer">
                <FaTwitter color={isTabletOrMobile ? "#C0C0C0" : "#28282B"} style={{ marginLeft: "14px", minHeight: "24px", minWidth: "24px"}} />
              </a>
              <a href="https://www.instagram.com/rachellllynn5/" target="_blank" rel="noreferrer">
                <FaInstagram color={isTabletOrMobile ? "#C0C0C0" : "#28282B"} style={{ marginLeft: "14px", minHeight: "24px", minWidth: "24px"}} />
              </a>
            </div>
          </div>

          <div className="shane_tm_down loaded">
            <div className="line_wrapper">
              <div className="line"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Slider;
