import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const partnerList = [
  { img: "recur-logo.png", animationDelay: "" },
  { img: "PerseusHouse_logo.png", animationDelay: "30" },
  { img: "blockchain-com-logo.png", animationDelay: "60" },
  { img: "nhl-logo.png", animationDelay: "90" },
  { img: "remington-logo.png", animationDelay: "120" },
  { img: "drybar_logo.png", animationDelay: "150" },
  { img: "Rocksbox_logo.png", animationDelay: "180" },
  { img: "godiva-chocolatier-logo.png", animationDelay: "210" },
];

const settings = {
  dots: false,
  arrow: false,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 2,
  autoplay: true,
  speed: 2000,
  responsive: [
    {
      breakpoint: 420,
      settings: {
        slidesToShow: 2,
      },
    },
  ],
};

export default function BrandAnimation() {
  return (
    <ul>
      <Slider {...settings}>
        {partnerList.map((val, i) => (
          <li className="item" key={i}>
            <img
              src={`img/partners/${val.img}`}
              alt="partners brand"
              height="100px"
            />
          </li>
        ))}
      </Slider>
    </ul>
  );
}
