import React from "react";
import Header from "../components/header/Header";
import Slider from "../components/slider/Slider";
import About from "../components/about/About";
import Portfolio from "../components/portfolio/Portfolio";
import Video from "../components/video/Video";
import Brand from "../components/BrandAnimation";
import Enquiry from "../components/enquiry/Enquiry";
import Footer from "../components/footer/Footer";

const Home = () => {
  return (
    <div className="home-two">
      <Header />
      <Slider />
      <About />
      <Portfolio />
      <Video />

      <div className="shane_tm_section">
        <div className="shane_tm_partners">
          <div className="container">
            <div className="partners_inner">
              <Brand />
            </div>
          </div>
        </div>
      </div>

      <Enquiry />
      <Footer />
    </div>
  );
};

export default Home;
