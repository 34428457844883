import React, { useState } from "react";
import Scrollspy from "react-scrollspy";
import { NavLink } from "react-router-dom";
import {FaInstagram, FaLinkedin, FaTwitter} from "react-icons/fa";

const Header = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbar, setNavbar] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <div className="shane_tm_topbar">
        <div className={navbar ? "topbar_inner opened" : "topbar_inner"}>
          <div className="logo">
            <NavLink to="/">
              <img src="/img/header/logo.png" alt="logo" height="40px" />
            </NavLink>
          </div>
          <div className="menu">
            <Scrollspy
              className="anchor_nav"
              items={["home", "about", "portfolio", "contact"]}
              currentClassName="current"
              offset={-200}
            >
              <li>
                <a href="#home">Home</a>
              </li>
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#portfolio">Portfolio</a>
              </li>
              <li>
                <a href="#contact">Contact</a>
              </li>
            </Scrollspy>
          </div>
        </div>
      </div>

      <div className="shane_tm_mobile_menu">
        <div className="topbar_inner">
          <div className="container bigger">
            <div className="topbar_in">
              <div className="logo">
                <NavLink to="/">
                  <img src="/img/header/logo.png" alt="logo" height="28px" />
                </NavLink>
              </div>
              <div className="my_trigger" onClick={handleClick}>
                <div
                  className={
                    click
                      ? "hamburger hamburger--collapse-r is-active"
                      : "hamburger"
                  }
                >
                  <div className="hamburger-box">
                    <div className="hamburger-inner"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={click ? "dropdown active" : "dropdown"}>
          <div className="container">
            <span className="close_menu" onClick={handleClick}>
              Close
            </span>
            <div className="dropdown_inner">
              <ul className="anchor_nav">
                <li className="current">
                  <a href="#home" onClick={handleClick}>
                    Home
                  </a>
                </li>
                <li>
                  <a href="#about" onClick={handleClick}>
                    About
                  </a>
                </li>
                <li>
                  <a href="#portfolio" onClick={handleClick}>
                    Portfolio
                  </a>
                </li>
                <li>
                  <a href="#contact" onClick={handleClick}>
                    Contact
                  </a>
                </li>
              </ul>
              <div className="social-menu">
                <div>
                  <a href="https://www.linkedin.com/in/rachel-golden-10b56410b/" target="_blank" rel="noreferrer">
                    <FaLinkedin className="social-icon" color="white" style={{ minHeight: "18px", minWidth: "18px"}} />
                  </a>
                  <a href="https://twitter.com/rachellllynn5" target="_blank" rel="noreferrer">
                    <FaTwitter className="social-icon" color="white" style={{ marginLeft: "12px", minHeight: "18px", minWidth: "18px"}} />
                  </a>
                  <a href="https://www.instagram.com/rachellllynn5/" target="_blank" rel="noreferrer">
                    <FaInstagram className="social-icon" color="white" style={{ marginLeft: "12px", minHeight: "18px", minWidth: "18px"}} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
