import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Gallery, Item } from "react-photoswipe-gallery";

const tabList = ["Partnerships", "Branding", "Events", "NFT Experiences", "Press"];

const tabListContent = [
  {
    portfolioItems: [
      {
        img: "/img/portfolio/partnerships/Blockchain.com_DakPrescott_BTS4.png",
        title: "Dallas Cowboys",
        meta: "Partnerships",
        portfolioLink: "https://www.youtube.com/watch?v=cjBC5ODi8y8",
      },
      {
        img: "/img/portfolio/partnerships/Blockchain.com_DakPrescott_BTS_2.jpg",
        title: "Dallas Cowboys",
        meta: "Partnerships",
        portfolioLink: "https://medium.com/blockchain/trusted-by-millions-trusted-by-americas-team-8f89ef4a043f",
      },
      {
        img: "/img/portfolio/partnerships/Blockchain.com_Cloud9_BrandPartnership.jpeg",
        title: "Cloud9",
        meta: "Partnerships",
        portfolioLink:
          "https://medium.com/blockchain/blockchain-com-and-cloud9-partner-to-build-the-future-of-finance-and-gaming-968cecd45701",
      },
      {
        img: "/img/portfolio/partnerships/Blockchain.com_MiamiPadelOpen_Partnership.jpeg",
        title: "Miami Padel Open",
        meta: "Partnerships",
        portfolioLink:
          "https://medium.com/blockchain/blockchain-com-miami-padel-open-2022-b92b026f10f",
      },
    ],
  },
  {
    portfolioItems: [
      {
        img: "/img/portfolio/branding/RECUR_TheRECURringTimes.png",
        title: "The RECURring Times",
        meta: "Branding",
        portfolioLink:
          "https://www.recur.com/newsletter",
      },
      {
        img: "/img/portfolio/branding/RECUR_Rebrand.png",
        title: "RECUR Rebrand",
        meta: "Branding",
        portfolioLink:
          "https://www.recur.com/blog/recur-rebrand-new-look-same-mission",
      },
      {
        img: "/img/portfolio/branding/Blockchain.com_TheNextEra.png",
        title: "The Next Era",
        meta: "Branding",
        portfolioLink:
          "https://medium.com/blockchain/the-blockchain-com-2020-experience-325ab80e2d88",
      },
      {
        img: "/img/portfolio/branding/Blockchain.com_10th_birthday.gif",
        title: "10th Birthday",
        meta: "Branding",
        portfolioLink:
          "https://medium.com/blockchain/for-our-10th-birthday-were-giving-away-bitcoin-eeac0afb8bf8",
      },
      {
        img: "/img/portfolio/branding/Blockchain.com_2021WrappedUp.png",
        title: "2021 Wrapped Up",
        meta: "Branding",
        portfolioLink:
          "https://medium.com/blockchain/blockchain-coms-2021-wrapped-up-4598842c99d",
      },
    ],
  },
  {
    portfolioItems: [
      {
        img: "/img/portfolio/events/Blockchain.com_BitcoinMiami2021.png",
        title: "Bitcoin Miami 2021",
        meta: "Events",
        portfolioLink: "https://medium.com/blockchain/blockchain-com-to-sponsor-bitcoin-2021-9ff80162c7ac",
      },
      {
        img: "/img/portfolio/events/Blockchain.com_BitcoinMiami2022.png",
        title: "Bitcoin Miami 2022",
        meta: "Events",
        portfolioLink: "https://medium.com/blockchain/blockchain-com-sponsors-bitcoin-miami-and-hosts-5k-bitcoin-giveaway-at-vip-lounge-80ae7d79938f",
      },
      {
        img: "/img/portfolio/events/Blockchain.com_Consensus2022.png",
        title: "Consensus 2022",
        meta: "Events",
        portfolioLink: "https://medium.com/blockchain/blockchain-com-sponsors-consensus-2022-3d1296979a2a",
      },
      {
        img: "/img/portfolio/events/RECUR_GDC2023.jpg",
        title: "GDC 2023",
        meta: "Events",
        portfolioLink: "https://www.recur.com/blog/gamers-assembled-a-look-back-at-gdc-2023",
      },
      {
        img: "/img/portfolio/events/Blockchain.com_Institutional_Dinner.jpg",
        title: "Institutional Dinner",
        meta: "Events",
        portfolioLink: "https://medium.com/blockchain/blockchain-launches-institutional-product-blockchain-principal-strategies-97581fe56a79",
      },
    ],
  },
  {
    portfolioItems: [
      {
        img: "/img/portfolio/nft-experiences/RECUR_Nickelodeon.png",
        title: "Nickelodeon",
        meta: "NFT Experiences",
        portfolioLink:
          "https://www.recur.com/blog/utility-at-the-splat-factory-is-warming-up",
      },
      {
        img: "/img/portfolio/nft-experiences/RECUR_TMNT.png",
        title: "TMNT",
        meta: "NFT Experiences",
        portfolioLink:
          "https://www.recur.com/blog/breaking-nyc-turtle-adoptions-worth-shell-ebrating",
      },
      {
        img: "/img/portfolio/nft-experiences/RECUR_StarTrekContinuum.png",
        title: "Star Trek™ Continuum",
        meta: "NFT Experiences",
        portfolioLink:
          "https://www.recur.com/blog/the-star-trek-tm-continuum-experience",
      },
      {
        img: "/img/portfolio/nft-experiences/RECUR_Playtika.png",
        title: "Playtika",
        meta: "NFT Experiences",
        portfolioLink:
          "https://www.recur.com/blog/playtika-launches-nft-gaming-experience-for-their-beloved-game-slotomania-built-on-recur",
      },
    ],
  },
  {
    portfolioItems: [
      {
        img: "/img/portfolio/press/RECUR_DewDrops.png",
        title: "Dew Drops",
        meta: "Press",
        portfolioLink:
          "https://www.recur.com/blog/dew-drops-the-next-generation-of-text-based-drops-built-on-recur",
      },
      {
        img: "/img/portfolio/press/RECUR_RECURPortal.png",
        title: "RECUR Portal",
        meta: "Press",
        portfolioLink:
          "https://www.recur.com/blog/introducing-recur-portal-the-experiences-you-love-all-in-one-place",
      },
      {
        img: "/img/portfolio/press/RECUR_Tafi.png",
        title: "Tafi",
        meta: "Press",
        portfolioLink:
          "https://www.prnewswire.com/news-releases/recur-and-tafi-partner-to-bring-innovative-web3-ip-experiences-to-market-301785395.html#:~:text=MIAMI%2C%20March%2030%2C%202023%20%2F,IP%20experiences%20Powered%20by%20RECUR.",
      },
      {
        img: "/img/portfolio/press/RECUR_Near.png",
        title: "Near",
        meta: "Press",
        portfolioLink:
          "https://www.prnewswire.com/news-releases/recur-and-near-protocol-partner-to-enhance-interoperability-and-accessibility-in-web3-301751770.html",
      },
    ],
  },
];

const Portfolio = () => {
  return (
    <div className="shane_tm_section" id="portfolio">
      <div className="shane_tm_portfolio">
        <div className="container">
          <div className="position-relative">
            <div className="shane_tm_title">
              <div className="title_flex">
                <div className="left">
                  <span>Portfolio</span>
                  <h3 className="heading">Marketing</h3>
                </div>
              </div>
            </div>

            <div className="portfolio_filter">
              <Tabs>
                <TabList >
                  {tabList.map((val, i) => (
                    <Tab key={i}>{val}</Tab>
                  ))}
                </TabList>

                <div className="portfolio_list has-effect">
                  <Gallery>
                    {tabListContent.map((tabContent, i) => (
                      <TabPanel key={i}>
                        <ul className="gallery_zoom">
                          {tabContent.portfolioItems.map((val, i) => (
                            <li
                              key={i}
                              data-aos="fade-right"
                              data-aos-duration="1200"
                              data-aos-delay={val.delayAnimation}
                            >
                              <div className="inner">
                                <div className="entry">
                                  <Item
                                    original={val.img}
                                    thumbnail={val.img}
                                    width={1000}
                                    height={1000}
                                  >
                                    {({ ref }) => (
                                      <img
                                        src={val.img}
                                        alt="portfolio"
                                        role="button"
                                        ref={ref}
                                        onClick={() => window.open(val.portfolioLink, '_blank')}
                                      />
                                    )}
                                  </Item>
                                </div>
                                <div className="mobile_title">
                                  <h3>
                                    <a
                                      href={val.portfolioLink}
                                      rel="noopener noreferrer"
                                      target="_blank"
                                    >
                                      {val.title}
                                    </a>
                                  </h3>
                                  <span>{val.meta}</span>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      </TabPanel>
                    ))}
                  </Gallery>
                </div>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portfolio;
