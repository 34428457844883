import React from "react";

const About = () => {
  return (
    <>
      <div className="shane_tm_section" id="about">
        <div className="shane_tm_about">
          <div className="container">
            <div className="about_inner">
              <div className="left">
                <div
                  className="image"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <img src="/img/header/rachel-golden-headshot3.png" alt="rachel golden headshot" />

                  <div
                    className="main"
                    style={{ backgroundImage: "url(img/header/rachel-golden-headshot3.png)" }}
                  ></div>
                </div>
              </div>

              <div className="right">
                <div
                  className="shane_tm_title"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <span>About</span>
                  <h3 className="heading">Brand & Digital Marketer</h3>
                  <h5 className="heading"> Charlotte, NC</h5>
                </div>
                <div
                  className="text"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <p>
                    Hi there! I'm Rachel, a versatile marketing professional with nearly
                    a decade of experience. I'm passionate about creating engaging
                    content and crafting real value for businesses. As an avid reader,
                    I'm drawn to a brand's ability to use storytelling to understand
                    and connect with users. I love connecting with companies, learning their
                    why, and bringing their vision to life with brand and digital marketing.
                  </p>
                </div>
                <div
                  className="shane_tm_button"
                  data-aos="fade-up"
                  data-aos-duration="1200"
                >
                  <a href="/Rachel_Golden_Resume.pdf" download>
                    <span style={{ fontWeight: "600"}}>Download CV</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
