import React from "react";

const Enquiry = () => {
  return (
    <div className="shane_tm_section" id="contact">
      <div
        className="shane_tm_talk bg_image_props"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "img/backgrounds/city.jpg"
          })`,
        }}
      >
        <div className="shape">
          <img className="svg" src="/img/backgrounds/paper.svg" alt="torn paper border" />
        </div>

        <div className="background" id="talk">
          <a className="player"></a>
          <div className="overlay"></div>
        </div>

        <div className="talk_inner">
          <div className="text" data-aos="fade-up" data-aos-duration="1200">
            <h3 style={{ fontFamily: "IBM Plex Sans"}}>Let's work together!</h3>
          </div>

          <div
            className="button"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="100"
          >
            <a href="mailto:someone@yoursite.com?subject=Mail from Our Site" target="_blank" rel="noreferrer">
              <button className="white-fill-bg">
                <span style={{ fontWeight: "600"}}>Send Enquiry</span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Enquiry;
